angular
    .module('vm')
    .controller('MainController', MainController);

function MainController (
    $timeout, $modal, $scope, $http, CursorWait, Notifications, $translate, Auth, $location, $filter,
    RelationshipStatusSelect, ResponsiveService, UrlSearchParamsService
) {
    var vm = this;
    $scope.vm = vm;

    vm.part = '';
    vm.sendingForm = false;

    vm.username = '';
    vm.password = '';

    vm.setPart = setPart;
    vm.showLoginModal = showLoginModal;
    vm.showUnsubscribeModal = showUnsubscribeModal;
    vm.showForgotPasswordModal = showForgotPasswordModal;
    vm.submitLogin = submitLogin;
    vm.submitForgotPassword = submitForgotPassword;
    vm.facebookLogin = facebookLogin;
    vm.createAccount = createAccount;

    vm.unsubscribedStep = 'now'; // now, already, reminder, resubscribing, resubscribe-error, resubscribed
    vm.unsubscribeParams = {};

    vm.uResubscribe = uResubscribe;
    vm.unsubscribeInit = unsubscribeInit;

    vm.userDefinitionList = RelationshipStatusSelect.getUserDefinitionVM();
    vm.userDefinition = null;
    vm.isAgreedWithCookie = false;
    vm.isSetDefaultCookie = false;


    activate();

    //////////

    function activate() {

        $scope.$on('$locationChangeSuccess', () => {
            onLocationChange();
        });

        if (window.location.href.match(/\/settings\/unsubscribe\/\w+/)) {
            vm.showUnsubscribeModal();
            return;
        }

        onLocationChange();
    }

    function onLocationChange() {
        $timeout(function () {
            vm.part = $location.search().part;
            const modal = $location.search().modal;

            if (modal === 'login' || location.hash === '#/login') {
                showLoginModal();
            }
            else if (modal === 'forgot-password' || location.hash === '#/forgot-password') {
                showForgotPasswordModal();
            }
        });
    }

    function createAccount(id) {
        console.info('id', id);

        if (id !== undefined) {
            vm.userDefinition = $filter('filter')(vm.userDefinitionList, { id: id })[0];
        }

        setDefaultCookieFlag();
        
        const funnel = UrlSearchParamsService.get('f');

        if (ResponsiveService.isMobile() && funnel === 'may-19') {
            vm.setPart('may-19');
        } else {
            vm.setPart('create-account');
        }
    }

    function setPart(part) {
        // vm.step = step;
        // window.history.pushState('', '', '#!/?step=' + step);
        $location.search('part', part);
    }
    
    function showLoginModal() {
        $modal({
            templateUrl: 'login-modal.html',
            show: true,
            scope: $scope,
            backdrop: 'static'
        });
    }

    function showUnsubscribeModal() {
        $modal({
            templateUrl: 'unsubscribe-modal.component.html',
            show: true,
            scope: $scope,
        });
    }

    function showForgotPasswordModal() {
        $modal({
            templateUrl: 'forgot-password-modal.html',
            show: true,
            scope: $scope,
            backdrop: 'static'
        });
    }

    function submitLogin() {
        if (vm.sendingForm) {
            return;
        }

        vm.sendingForm = true;

        CursorWait.enable();

        var data = {
            username: vm.username,
            password: vm.password
        };

        $http.post('/api/v4.0/auth', data)
            .then(function (response) {
                if (response.data.redirectUrl) {
                    window.location.href = response.data.redirectUrl;
                    return;
                }
                Auth.redirectToFrontend(response.data.token);
            })
            .catch(function (response) {
                Notifications.add({
                    text: response.data.error
                });
            })
            .finally(function () {
                CursorWait.disable();
                vm.sendingForm = false;
            })
        ;
    }

    function submitForgotPassword() {
        if (vm.sendingForm) {
            return;
        }

        vm.sendingForm = true;

        CursorWait.enable();

        var data = {
            email: vm.email
        };

        $http.patch('/api/v4.0/account/password_reset', data)
            .then(function () {
                Notifications.add({
                    text: $translate.instant('reset_password.password_reset_sent')
                });
            })
            .catch(function (response) {
                var message = '';

                switch (response.status) {
                    case 404:
                        message = $translate.instant('error.reset_password_account_not_found');
                        break;
                    case 409:
                        message = $translate.instant('error.password_already_requested');
                        break;
                }

                Notifications.add({
                    text: message
                });
            })
            .finally(function () {
                CursorWait.disable();
                vm.sendingForm = false;
            })
        ;
    }

    function facebookLogin() {
        Auth.oAuthLogin('facebook')
            .then(function(response){
                Auth.redirectToFrontend(response.token);
            })
        ;
    }

    function uResubscribe () {
        vm.unsubscribedStep = 'resubscribing';
        var url = '/settings/resubscribe/' + vm.unsubscribeParams['resubscribe_token'];
        CursorWait.enable();
        $http.get(url)
            .then(function () {
                vm.unsubscribedStep = 'resubscribed';
            })
            .catch(function (response) {
                vm.unsubscribedStep = 'resubscribe-error';
            })
            .finally(function () {
                CursorWait.disable();
            })
        ;
    }

    function unsubscribeInit (params) {
        vm.unsubscribeParams = params;
        if (vm.unsubscribeParams['unsubscribed']) {
            vm.unsubscribedStep = 'now';
        } else
        if (vm.unsubscribeParams['already_unsubscribed']) {
            vm.unsubscribedStep = 'already';
        } else {
            vm.unsubscribedStep = 'reminder';
        }
    }

    function setDefaultCookieFlag() {
        if (!vm.isAgreedWithCookie) {
            vm.isSetDefaultCookie = true;
        }
    }
}