'use strict';

angular.module('vm').controller('MainController', MainController);

function MainController($timeout, $modal, $scope, $http, CursorWait, Notifications, $translate, Auth, $location, $filter, RelationshipStatusSelect, ResponsiveService, UrlSearchParamsService) {
    var vm = this;
    $scope.vm = vm;

    vm.part = '';
    vm.sendingForm = false;

    vm.username = '';
    vm.password = '';

    vm.setPart = setPart;
    vm.showLoginModal = showLoginModal;
    vm.showUnsubscribeModal = showUnsubscribeModal;
    vm.showForgotPasswordModal = showForgotPasswordModal;
    vm.submitLogin = submitLogin;
    vm.submitForgotPassword = submitForgotPassword;
    vm.facebookLogin = facebookLogin;
    vm.createAccount = createAccount;

    vm.unsubscribedStep = 'now'; // now, already, reminder, resubscribing, resubscribe-error, resubscribed
    vm.unsubscribeParams = {};

    vm.uResubscribe = uResubscribe;
    vm.unsubscribeInit = unsubscribeInit;

    vm.userDefinitionList = RelationshipStatusSelect.getUserDefinitionVM();
    vm.userDefinition = null;
    vm.isAgreedWithCookie = false;
    vm.isSetDefaultCookie = false;

    activate();

    //////////

    function activate() {

        $scope.$on('$locationChangeSuccess', function () {
            onLocationChange();
        });

        if (window.location.href.match(/\/settings\/unsubscribe\/\w+/)) {
            vm.showUnsubscribeModal();
            return;
        }

        onLocationChange();
    }

    function onLocationChange() {
        $timeout(function () {
            vm.part = $location.search().part;
            var modal = $location.search().modal;

            if (modal === 'login' || location.hash === '#/login') {
                showLoginModal();
            } else if (modal === 'forgot-password' || location.hash === '#/forgot-password') {
                showForgotPasswordModal();
            }
        });
    }

    function createAccount(id) {
        console.info('id', id);

        if (id !== undefined) {
            vm.userDefinition = $filter('filter')(vm.userDefinitionList, { id: id })[0];
        }

        setDefaultCookieFlag();

        var funnel = UrlSearchParamsService.get('f');

        if (ResponsiveService.isMobile() && funnel === 'may-19') {
            vm.setPart('may-19');
        } else {
            vm.setPart('create-account');
        }
    }

    function setPart(part) {
        // vm.step = step;
        // window.history.pushState('', '', '#!/?step=' + step);
        $location.search('part', part);
    }

    function showLoginModal() {
        $modal({
            templateUrl: 'login-modal.html',
            show: true,
            scope: $scope,
            backdrop: 'static'
        });
    }

    function showUnsubscribeModal() {
        $modal({
            templateUrl: 'unsubscribe-modal.component.html',
            show: true,
            scope: $scope
        });
    }

    function showForgotPasswordModal() {
        $modal({
            templateUrl: 'forgot-password-modal.html',
            show: true,
            scope: $scope,
            backdrop: 'static'
        });
    }

    function submitLogin() {
        if (vm.sendingForm) {
            return;
        }

        vm.sendingForm = true;

        CursorWait.enable();

        var data = {
            username: vm.username,
            password: vm.password
        };

        $http.post('/api/v4.0/auth', data).then(function (response) {
            if (response.data.redirectUrl) {
                window.location.href = response.data.redirectUrl;
                return;
            }
            Auth.redirectToFrontend(response.data.token);
        }).catch(function (response) {
            Notifications.add({
                text: response.data.error
            });
        }).finally(function () {
            CursorWait.disable();
            vm.sendingForm = false;
        });
    }

    function submitForgotPassword() {
        if (vm.sendingForm) {
            return;
        }

        vm.sendingForm = true;

        CursorWait.enable();

        var data = {
            email: vm.email
        };

        $http.patch('/api/v4.0/account/password_reset', data).then(function () {
            Notifications.add({
                text: $translate.instant('reset_password.password_reset_sent')
            });
        }).catch(function (response) {
            var message = '';

            switch (response.status) {
                case 404:
                    message = $translate.instant('error.reset_password_account_not_found');
                    break;
                case 409:
                    message = $translate.instant('error.password_already_requested');
                    break;
            }

            Notifications.add({
                text: message
            });
        }).finally(function () {
            CursorWait.disable();
            vm.sendingForm = false;
        });
    }

    function facebookLogin() {
        Auth.oAuthLogin('facebook').then(function (response) {
            Auth.redirectToFrontend(response.token);
        });
    }

    function uResubscribe() {
        vm.unsubscribedStep = 'resubscribing';
        var url = '/settings/resubscribe/' + vm.unsubscribeParams['resubscribe_token'];
        CursorWait.enable();
        $http.get(url).then(function () {
            vm.unsubscribedStep = 'resubscribed';
        }).catch(function (response) {
            vm.unsubscribedStep = 'resubscribe-error';
        }).finally(function () {
            CursorWait.disable();
        });
    }

    function unsubscribeInit(params) {
        vm.unsubscribeParams = params;
        if (vm.unsubscribeParams['unsubscribed']) {
            vm.unsubscribedStep = 'now';
        } else if (vm.unsubscribeParams['already_unsubscribed']) {
            vm.unsubscribedStep = 'already';
        } else {
            vm.unsubscribedStep = 'reminder';
        }
    }

    function setDefaultCookieFlag() {
        if (!vm.isAgreedWithCookie) {
            vm.isSetDefaultCookie = true;
        }
    }
}
'use strict';

angular.module('vm').component('appCreateAccount', {
    templateUrl: 'create-account.component.html',
    bindings: {
        userDefinition: "="
    },
    controllerAs: 'vm',
    controller: function controller(RelationshipStatusSelect, DateSelect, $http, CursorWait, $cookies, SignupFetchErrorMessage, Notifications, CONFIG, Auth, $api, $q, $timeout, ResponsiveService) {
        var vm = this;

        vm.subStep = null;

        vm.enums = null;

        vm.email = '';
        vm.username = '';
        vm.password = '';
        vm.birthDay = '';
        vm.birthMonth = '';
        vm.birthYear = '';

        vm.authPromise = null;
        vm.userCreated = false;

        vm.emailHasMistake = false;
        vm.emailToReplace = null;
        vm.emailUndeliverable = false;
        vm.emailError = null;
        vm.nameError = null;

        vm.days = DateSelect.getDays();
        vm.months = DateSelect.getMonths();
        vm.years = DateSelect.getYears();

        vm.agreeWithTerms = false;
        vm.agreeWithEmailMarketing = false;

        vm.userDefinitionMap = RelationshipStatusSelect.getUserDefinitionMap();

        vm.accountDetails = {
            sex: null,
            relationshipStatus: null,
            seekingSex: null,
            email: null,
            username: null,
            password: null,
            birthDate: null
        };

        vm.sendingForm = false;

        vm.backToStep = backToStep;
        vm.setSex = setSex;
        vm.setRelationshipStatus = setRelationshipStatus;
        vm.setSeekingSex = setSeekingSex;
        vm.setEmail = setEmail;
        vm.setUsername = setUsername;
        vm.setPassword = setPassword;
        vm.setBirthDate = setBirthDate;
        vm.captchaSuccess = captchaSuccess;
        vm.sendForm = sendForm;
        vm.updateUser = updateUser;
        vm.getFullDate = getFullDate;
        vm.emailContinue = emailContinue;
        vm.replaceEmail = replaceEmail;
        vm.scrollToBottom = scrollToBottom;

        vm.$onInit = onInit;

        function onInit() {
            if (vm.userDefinition) {
                var map = vm.userDefinitionMap[vm.userDefinition.id];
                vm.accountDetails.sex = map['sex'];
                vm.accountDetails.seekingSex = map['seekingSex'];
                vm.accountDetails.relationshipStatus = map['relationshipStatus'];
            }

            goNext();
            getEnums();

            fireGAPageView();
            fireGAEvent('Registration Funnel', 'Load', '');
        }

        function backToStep(step) {
            vm.subStep = step;
            vm.accountDetails[step] = null;
        }

        function getEnums() {
            $api.get('enums/profile').then(function (response) {
                vm.enums = response.data;
            });
        }

        function setSex(sex) {
            vm.accountDetails.sex = sex;
            updateBasicFields();
            goNext();

            fireGAEvent('Registration Funnel', 'Gender', sex);
        }

        function setRelationshipStatus(relationshipStatus) {
            vm.accountDetails.relationshipStatus = relationshipStatus;
            updateBasicFields();
            goNext();

            fireGAEvent('Registration Funnel', 'Relationship status', relationshipStatus);
        }

        function setSeekingSex(seekingSex) {
            vm.accountDetails.seekingSex = seekingSex;
            updateBasicFields();
            goNext();

            fireGAEvent('Registration Funnel', 'Seeking sex', seekingSex);
        }

        function setEmail() {
            checkEmail();
        }

        function emailContinue() {
            vm.emailHasMistake = false;
            vm.emailUndeliverable = false;
            vm.accountDetails.email = vm.email;
            sendForm();
            goNext();
        }

        function setUsername() {
            checkUsername();
        }

        function setPassword() {
            vm.accountDetails.password = vm.password;
            goNext();

            fireGAEvent('Registration Funnel', 'Password', '');
        }

        function setBirthDate() {
            var daysInMonth = null;
            var date = new Date();

            if (vm.birthYear) {
                date.setYear(vm.birthYear.id);
            }

            if (vm.birthMonth) {
                date.setMonth(parseInt(vm.birthMonth.id), 0);
                daysInMonth = date.getDate();
                vm.days = DateSelect.getDays(daysInMonth);
            }

            if (vm.birthDay) {
                if (typeof daysInMonth === 'number' && daysInMonth < vm.birthDay.id) {
                    vm.birthDay = '';
                }
            }

            if (vm.birthDay && vm.birthMonth && vm.birthYear) {
                vm.accountDetails.birthDate = vm.birthYear.id + '-' + vm.birthMonth.id + '-' + vm.birthDay.id;
                goNext();

                fireGAEvent('Registration Funnel', 'Birth date', vm.birthYear.id);
            }
        }

        function goNext() {
            vm.subStep = null;

            console.log(vm.accountDetails);

            Object.keys(vm.accountDetails).forEach(function (key) {
                if (!vm.accountDetails[key]) {
                    if (!vm.subStep) {
                        vm.subStep = key;
                    }
                }
            });
        }

        function checkEmail() {
            vm.emailError = null;

            vm.checkEmailPromise = $api.get('account/check-available-email?email=' + encodeURIComponent(vm.email)).then(function (response) {
                var isOk = true;

                if (response.data.hasMistake) {
                    vm.emailHasMistake = true;
                    vm.emailToReplace = response.data.emailToReplace;
                    isOk = false;
                }

                if (response.data.notUnique) {
                    vm.emailError = response.data.notUnique;
                    isOk = false;
                }

                if (response.data.notDeliverable) {
                    vm.emailError = response.data.notDeliverable;
                    isOk = false;
                }

                if (response.data.validationError) {
                    vm.emailError = response.data.validationError;
                    isOk = false;
                }

                if (isOk) {
                    fireGAEvent('Registration Funnel', 'E-mail', '');
                    vm.accountDetails.email = vm.email;

                    sendForm();
                    goNext();
                }
            });
        }

        function checkUsername() {
            vm.nameError = null;

            vm.checkNamePromise = $api.get('account/check-available-name?name=' + encodeURIComponent(vm.username)).then(function (response) {
                if (response.data.success) {
                    vm.nameError = null;
                    vm.accountDetails.username = vm.username;
                    goNext();

                    fireGAEvent('Registration Funnel', 'Username', vm.username);
                } else {
                    vm.nameError = response.data.error;
                }
            });
        }

        function replaceEmail() {
            vm.email = vm.emailToReplace;
            vm.accountDetails.email = vm.email;
            vm.emailHasMistake = false;
            vm.emailUndeliverable = false;

            fireGAEvent('Registration Funnel', 'E-mail', '');

            sendForm();
            goNext();
        }

        function sendForm() {
            if (!vm.authPromise) {
                var data = {
                    api_signup_by_group: {
                        'group': 'vm',
                        'email': vm.accountDetails.email,
                        'sex': vm.accountDetails.sex,
                        'relationshipStatus': vm.accountDetails.relationshipStatus,
                        'seekingSex': vm.accountDetails.seekingSex,
                        'agreeWithTerms': vm.agreeWithTerms,
                        'agreeWithEmailMarketing': vm.agreeWithEmailMarketing
                    }
                };

                vm.authPromise = $api.post('account/signup-by-group', data).then(function (response) {
                    vm.userCreated = response.data;

                    $http.defaults.headers.common['Authorization'] = CONFIG.projectName + ' ' + response.data.token;

                    return response.data;
                }).catch(function (response) {
                    var message = SignupFetchErrorMessage(response.data);

                    if (message) {
                        Notifications.add({
                            html: message
                        });
                    }
                });
            } else {
                vm.authPromise.then(function () {
                    $api.post('settings/change-unconfirmed-email', { email: vm.accountDetails.email });
                });
            }

            fireGAEvent('Registration Funnel', 'Send email', '');
        }

        function updateBasicFields() {
            // if (vm.userCreated) {
            //     let data = {
            //         'sex': vm.accountDetails.sex,
            //         'sugar': vm.accountDetails.sugarType,
            //         'seekingSex': vm.accountDetails.seekingSex
            //     };
            //
            //     $api.patch('profile/' + vm.userCreated.userId, data);
            // }
        }

        function updateUser() {
            if (vm.sendingForm) {
                return;
            }

            fireGAEvent('Registration Funnel', 'Send complete form', '');

            vm.sendingForm = true;
            CursorWait.enable();

            var data = {
                api_signup_by_group: {
                    'group': 'vm_additional',
                    'name': vm.accountDetails.username,
                    'birthDate': vm.accountDetails.birthDate,
                    'password': vm.accountDetails.password
                }
            };

            vm.authPromise.then(function (authData) {
                $q.all([$api.post('account/signup-by-group/additional', data)]).then(function () {
                    Auth.redirectToFrontend(authData.token);
                    CursorWait.disable();
                    vm.sendingForm = false;
                }).catch(function (response) {
                    var message = SignupFetchErrorMessage(response.data);

                    if (message) {
                        Notifications.add({
                            html: message
                        });
                    }

                    CursorWait.disable();
                    vm.sendingForm = false;
                });
            });
        }

        function getFullDate(dateString) {}

        function captchaSuccess() {
            fireGAEvent('Registration Funnel', 'Captcha success', '');
        }

        function fireGAEvent(category, action, label) {
            if ('ga' in window) {
                var event = {
                    hitType: 'event',
                    eventCategory: category,
                    eventAction: action,
                    eventLabel: label
                };

                ga('send', event);

                console.log('Send GA event', event);
            }
        }

        function fireGAPageView() {
            if ('ga' in window) {
                var pageView = { 'page': '/#/create-account' };

                ga('send', 'pageview', pageView);

                console.log('Send GA pageview', pageView);
            }
        }

        function scrollToBottom() {
            if (ResponsiveService.isMobile()) {
                $timeout(function () {
                    window.scrollTo(0, document.body.scrollHeight);
                }, 300);
            }
        }
    }
});
'use strict';

angular.module('vm').component('appSignupQuiz', {
    templateUrl: 'signup-quiz.component.html',
    bindings: {
        step: '=',
        userDefinition: "="
    },
    controllerAs: 'vm',
    controller: function controller() {
        var vm = this;
    }
});
'use strict';

angular.module('vm').component('appVmcPromoAlert', {
    templateUrl: 'vmc-promo-alert.component.html',
    bindings: {
        country: '='
    },
    controllerAs: 'vm',
    controller: function controller() {
        var vm = this;
    }
});