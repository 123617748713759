angular
    .module('vm')
    .component('appSignupQuiz', {
        templateUrl: 'signup-quiz.component.html',
        bindings: {
            step: '=',
            userDefinition: "="
        },
        controllerAs: 'vm',
        controller: function () {
            var vm = this;
        }
    })
;